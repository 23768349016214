import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import books from '../pages/booksData';

import './_Home.scss';

import audioIcon from '../assets/audio_icon.png';

import {tagsToPlexure} from '../utils/_mcd-connector';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: undefined
    };
  }

  componentDidMount () {
    window.onpopstate = null;
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'page_view',
      eventProperties: {
        page_virtual_path: '/family/luister-verhalen',
        page_type: 'family'
      }
    });
  }

  goTo = (action, redirect) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'button_click',
      eventAction: action,
      eventLabel: `/family/luister-verhalen/book${redirect}`
    });

    this.setState({ redirect });
  }

  render() {
    if (this.state.redirect) {
      return(<Redirect to={`/book${this.state.redirect}`} />)
    } else {
      return(
        <section className='home'>
          <>
            <h1>Welk verhaal wil je horen?</h1>
            <p className='home-description'>
              Klik op een van de verhalen hieronder en geniet van de avonturen van de
              Boomtop- tweelingen. Veel luisterplezier!</p>
          </>

          {books && Object.keys(books).length > 1 &&
            Object.keys(books)
              .filter((bookName) => books[bookName].isVisible)
              .map((bookName, index) => {
                  let book = books[bookName];
                  return (
                    <div key={index} className={`card ${book.className}`} onClick={() => {
                      tagsToPlexure(['McDNL_MDB_']);
                      this.goTo(`${book.description}`, book.redirectTo);
                    }}>
                      <img className='card__icon' src={audioIcon} alt=''/>
                      <img className='card__img' src={book.bookCover} alt={`Listen to ${book.title}`}/>
                      <span className='card__number number'>{`${index + 1}.`}</span>
                      <strong className='card__heading'>{book.title}</strong>
                      <p>{book.description}</p>
                    </div>
                  )
                }
              )}
        </section>
      )
    }
  }
}

export default Home;
